<!--
 * @Author: ocean
 * @Description: 数据统计 运营数据统计 时期统计列表
-->
<template>
  <scroll-layout class="periodStatistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParam"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div class="wrap-box">
      <div class="tags" v-show="switchOver">
        报表统计口径说明
        <span @click="tagClick()">?</span>
      </div>
      <div v-if="!switchOver">
        <statistics-chart
          ref="statisticsChart"
          :page="'platform'"
          :title="'充电趋势'"
          :isUpdate="isUpdate"
          :searchParams="searchParam"></statistics-chart>
      </div>
      <div v-if="switchOver">
        <ykc-table
          ref="YkcTable"
          operateFixedType="right"
          :data="tableData"
          :title="tableTitle"
          :columns="tableColumns"
          :operateButtons="tableOperateButtons">
          <template #headerButtons>
            <div class="table-header-button">
              <ykc-button v-rbac="'data:platform:period:export'" type="plain" @click="doExportFile">
                导出
              </ykc-button>
            </div>
          </template>
          <ykc-pagination
            align="right"
            slot="pagination"
            ref="YkcPagination"
            :total="pageInfo.total"
            :page-size.sync="pageInfo.size"
            :current-page.sync="pageInfo.current"
            @current-change="handlePaginationCurrentChange"
            @size-change="handlePaginationSizeChange"></ykc-pagination>
        </ykc-table>
        <ykc-operation-dialog
          size="large"
          title="统计口径说明"
          confirm-btn-txt="同意"
          :show-footer="false"
          :show.sync="operationStatus">
          <div slot="content">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              :autosize="{ minRows: 2 }"
              v-model="textarea2"></el-input>
          </div>
        </ykc-operation-dialog>
      </div>
    </div>
    <div class="bottomBtn" @click="switchOverFn">
      <div><i :class="switchOver ? 'el-icon-s-data' : 'el-icon-s-grid'"></i></div>
      <div>{{ switchOver ? '图表模式' : '列表模式' }}</div>
    </div>
  </scroll-layout>
</template>
<script>
  import { getLastTime, getLastYear, offlineExport } from '@/utils';
  import { dataStatisticsServe } from '@/service/apis';

  import statisticsChart from '../../components/dataStatisticsChart.vue';

  export default {
    name: 'periodStatisticsList',
    components: {
      statisticsChart,
    },
    data() {
      return {
        switchOver: true,
        topTip:
          '温馨提示：由于统计数据量过大，默认显示近7日统计数据，需要查看其他数据请使用查询功能。',
        tradeNo: null,
        isUpdate: false,
        topTipStatus: true,
        operationStatus: false,
        addressData: [], // 区域数据组件使用数据源
        stationIdData: [], // 归属电站组件使用数据源
        userOperatorList: [], // 归属商户组件使用数据源
        operatorUsersList: [], // 归属客户组件使用数据源
        searchParam: {
          date: {},
          cityId: '',
          endTime: '',
          startTime: '',
          dateType: '1',
          userCompanyId: '',
          stationIdList: [],
          stationOperatorList: [],
        },
        tableTitle: '明细列表', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '时间', prop: 'date', fixed: true, width: '200px', id: 0 },
          { label: '电量(度)', prop: 'chargedPower', width: '200px', id: 1 },
          { label: '充电时长(h)', prop: 'chargedTime', minWidth: '150px', id: 2 },
          { label: '充电次数', prop: 'chargedCount', minWidth: '150px', id: 3 },
          { label: '电费(元)', prop: 'chargedAmount', minWidth: '150px', id: 4 },
          { label: '服务费(元)', prop: 'serviceAmount', minWidth: '150px', id: 5 },
          { label: '订单总金额(元)', prop: 'totalAmount', minWidth: '160px', id: 6 },
          {
            label: '用户实付合计(元)',
            prop: 'chargedAmountAfterDiscount',
            minWidth: '160px',
            id: 7,
          },
          { label: '引流抽成费用(元)', prop: 'commissionAmount', minWidth: '160px', id: 8 },
          { label: '商户实收合计(元)', prop: 'operatorActualIncome', minWidth: '160px', id: 9 },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        textarea2: `电量(度)：所有历史订单电量汇总
  充电时长(h)：所有历史订单充电时长汇总
  充电次数：订单总数量
  电费(元)：所有历史订单原价电费
  服务费(元)：所有历史订单原价服务费
  订单总金额(元)：所有历史订单原价总金额
  用户实付合计(元)：订单总金额- 平台卡券优惠- 平台活动优惠-商户卡券优惠-商户活动优惠-会员折扣优惠
  引流抽成费用(元)：所有历史订单引流抽成总金额
  商户实收合计(元)：订单总金额-商户卡券优惠-商户活动优惠-引流抽成费用`,
      };
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.startTime = startDate;
      this.searchParam.endTime = endDate;

      this.requestList();
    },
    computed: {
      searchData() {
        return [
          {
            key: 'date',
            isDateType: true,
            label: '时间维度',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
        ];
      },
    },
    methods: {
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const reqParams = {
          ...pageInfos,
          cityId: this.searchParam.cityId,
          endTime: this.searchParam.endTime,
          startTime: this.searchParam.startTime,
          dateType: this.searchParam.dateType,
          userCompanyId: this.searchParam.userCompanyId,
          stationIdList: this.searchParam.stationIdList.map(String),
          stationOperatorList: this.searchParam.stationOperatorList.map(String),
        };
        dataStatisticsServe
          .trendDetailList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 统计说明
       */
      tagClick() {
        this.operationStatus = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'platform_charging_trend',
          jsonNode: {
            ...this.searchParam,
          },
        });
      },
      /**
       * 切换日月
       */
      handleChange(obj) {
        const { select, event } = obj;

        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(3, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          this.searchParam.dateType = '2';
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const startDateStr = getLastTime('yyyy-MM-dd', 7);
          const endDateStr = getLastTime('yyyy-MM-dd', 1);

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          this.searchParam.dateType = '1';
        }
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParam.startTime, this.searchParam.endTime] = searchData.date;
        }

        this.switchOver ? this.requestList() : this.$refs.statisticsChart.getCharts();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);

        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.startTime = startDate;
        this.searchParam.endTime = endDate;
        this.searchParam.dateType = '1';
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 切换到报表或图表模式
       */
      switchOverFn() {
        this.switchOver = !this.switchOver;
        console.log(this.switchOver);
        if (this.switchOver) {
          this.requestList();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .but-box {
    display: flex;
    justify-content: flex-end;
  }

  .periodStatistics-list {
    .wrap-box {
      position: relative;
      .tags {
        display: flex;
        align-items: center;
        font-size: 12px;
        position: absolute;
        top: 20px;
        left: 85px;
        > span {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: 8px;
          font-size: 10px;
          line-height: 16px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          border-radius: 50%;
          background: blue;
        }
      }

      .ykc-table {
        .el-table .el-table__row {
          .cell .userStatus {
            padding-left: 8px;
            position: relative;
          }

          .cell .userStatus::after {
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #d0021b;
            position: absolute;
            top: 5.5px;
            left: 0;
          }

          .cell .status-blue::after {
            background: #4d8cfd;
          }

          .cell .status-green::after {
            background: #02c498;
          }

          .cell .status-black::after {
            background: #9b9b9b;
          }
        }

        .sealBtn {
          color: #4d8cfd;
          cursor: pointer;
        }
      }
    }
    .bottomBtn {
      position: absolute;
      right: 10px;
      bottom: 20px;
      width: 90px;
      height: 45px;
      border-radius: 10px;
      color: #409eff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
      background-color: #fff;
      padding-right: 5px;
      cursor: pointer;
      z-index: 1000;

      i {
        margin: 0 5px;
      }
      &:hover {
        background-color: #f2f6fc;
      }
    }
  }
</style>
